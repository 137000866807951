export const WEBSITES = {
    FR: 'fr',
    ES: 'es',
    AD: 'ad',
    DE: 'de',
    CH: 'ch',
    PT: 'pt',
    EU: 'eu',
} as const;

// Env variables are accessibles only server side
export const WEBSITES_DOMAINS: Record<SupportedWebsite, string> = {
    [WEBSITES.FR]: process.env.FRONTEND_DOMAIN_FR as string,
    [WEBSITES.ES]: process.env.FRONTEND_DOMAIN_ES as string,
    [WEBSITES.AD]: process.env.FRONTEND_DOMAIN_AD as string,
    [WEBSITES.DE]: process.env.FRONTEND_DOMAIN_DE as string,
    [WEBSITES.CH]: process.env.FRONTEND_DOMAIN_CH as string,
    [WEBSITES.PT]: process.env.FRONTEND_DOMAIN_PT as string,
    [WEBSITES.EU]: process.env.FRONTEND_DOMAIN_EU as string,
};

const AVAILABLE_WEBSITES = Object.values(WEBSITES);

export type SupportedWebsite = typeof AVAILABLE_WEBSITES[number];

export const isValidWebsiteDomain = (domain: string) => Object.values(WEBSITES_DOMAINS).includes(domain);
