import CURRENCIES, { type SupportedCurrency } from '@/core/i18n/currencies';
import {
    LOCALES,
    type SupportedLocale,
    isSupportedLocale,
} from '@/core/i18n/locales';
import { WEBSITES, WEBSITES_DOMAINS, type SupportedWebsite } from '@/core/i18n/websites';

export const MAP_WEBSITE_TO_LOCALES: Record<SupportedWebsite, [SupportedLocale, ...SupportedLocale[]]> = {
    [WEBSITES.FR]: [LOCALES.FR],
    [WEBSITES.ES]: [LOCALES.ES, LOCALES.CA_ES],
    [WEBSITES.AD]: [LOCALES.CA],
    [WEBSITES.DE]: [LOCALES.DE],
    [WEBSITES.CH]: [LOCALES.FR_CH, LOCALES.DE_CH],
    [WEBSITES.PT]: [LOCALES.PT],
    [WEBSITES.EU]: [LOCALES.EN],
};

export const MAP_LOCALE_TO_WEBSITE = Object.entries(MAP_WEBSITE_TO_LOCALES).reduce((acc, [website, locales]) => {
    const websiteLocales = Object.fromEntries(locales.map((locale) => [locale, website]));

    return { ...acc, ...websiteLocales };
}, {} as Record<SupportedLocale, SupportedWebsite>);

export const MAP_WEBSITE_TO_CURRENCY: Record<SupportedWebsite, SupportedCurrency> = {
    [WEBSITES.FR]: CURRENCIES.EUR,
    [WEBSITES.ES]: CURRENCIES.EUR,
    [WEBSITES.AD]: CURRENCIES.EUR,
    [WEBSITES.DE]: CURRENCIES.EUR,
    [WEBSITES.CH]: CURRENCIES.CHF,
    [WEBSITES.PT]: CURRENCIES.EUR,
    [WEBSITES.EU]: CURRENCIES.EUR,
};

export const isLocaleValidForWebsite = (
    locale: string,
    domain: string,
): locale is SupportedLocale => {
    if (!isSupportedLocale(locale)) {
        return false;
    }

    const website = MAP_LOCALE_TO_WEBSITE[locale];

    return WEBSITES_DOMAINS[website] === domain;
};

export const buildFullBaseUrl = (domain: string, locale: SupportedLocale) => {
    const baseUrl = `https://${domain}`;

    const localizedUrl = ([LOCALES.CA_ES, LOCALES.DE_CH] as SupportedLocale[]).includes(locale)
        ? `${baseUrl}/${locale}`
        : baseUrl;

    return localizedUrl;
};
