import { useContext } from 'react';
import { WebsiteContext } from '@/utils/website/WebsiteProvider';

const useWebsiteConfig = () => {
    const value = useContext(WebsiteContext);

    if (!value) {
        throw new Error('Website context is not defined');
    }

    return value;
};

export default useWebsiteConfig;
