import classNames from 'classnames';
import React, { useEffect, useRef, useState, VFC } from 'react';
import Icon from '@/ui/components/icon';
import Typography from '@/ui/components/typography';
import useTranslation from '@/utils/hooks/useTranslation';
import { nl2br } from '@/utils/text';

const TooltipHelp: VFC<{ helpText: string, className?: string | null }> = ({ helpText, className }) => {
    const t = useTranslation();
    const tooltipRef = useRef<HTMLDivElement | null>(null);
    const [showHelp, setShowHelp] = useState(false);

    useEffect(() => {
        const hideTooltipHandler = (event: Event) => {
            if (event.target === tooltipRef.current && !showHelp) {
                return;
            }
            setShowHelp(false);
        };

        document.addEventListener('click', hideTooltipHandler);

        return () => {
            document.removeEventListener('click', hideTooltipHandler);
        };
    }, [tooltipRef, showHelp]);

    return (
        <div ref={tooltipRef} className={classNames('relative leading-none', className)}>
            <button
                className="cursor-help"
                onMouseEnter={() => setShowHelp(true)}
                onMouseLeave={() => setShowHelp(false)}
                type="button"
            >
                <Icon name="icon-info" size={14} />
                <span className="sr-only">{t('show_help')}</span>
            </button>
            <div className={classNames(
                { hidden: !showHelp },
                'p-4 bg-white w-[250px]',
                'shadow-[0_8px_16px_0_rgba(0,0,0,0.08)]',
                'z-10 absolute top-[20px] left-0',
            )}
            >
                <Typography as="span" className="text-dark-grey" variant="small">{nl2br(helpText)}</Typography>
            </div>
        </div>
    );
};

export default TooltipHelp;
