import { DidomiSDK } from '@didomi/react';
import getConfig from 'next/config';
import React, { FC } from 'react';

const DidomiScript: FC = () => {
    const { publicRuntimeConfig } = getConfig();
    const config = {
        user: {
            bots: {
                /**
                 * Indicate whether consent is required for bots
                 * Defaults to true
                 */
                consentRequired: false,

                /**
                 * Predefined types of bots to identify
                 * Defaults to all types supported by the SDK
                 * (Optional)
                 */
                types: ['crawlers', 'performance'],
            },
        },
        app: {
            logoUrl: '/logo.svg',
        },
        notice: {
            position: 'popup',
            logoAlignment: 'center',
        },
        theme: {
            color: '#E20031', // Principal color used by the SDK
            linkColor: '#E20031', // Color used for the links in the notice/popup
            font: 'Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"', // Font used by the SDK
            buttons: {
                regularButtons: { // Learn more/disagree/disagree to all buttons.
                    backgroundColor: '#eceff4',
                    textColor: '#464849',
                    borderColor: '#eceff4',
                    borderWidth: '0px',
                    borderRadius: '0px',
                },
                highlightButtons: { // Agree/save/agree to all buttons.
                    backgroundColor: '#E20031',
                    textColor: '#ffffff',
                    borderColor: '#E20031',
                    borderWidth: '0px',
                    borderRadius: '0px',
                },
            },
            css: `
                #didomi-host {
                    font-size: 14px;
                    line-height: 150%;
                }
                #didomi-host .didomi-popup-backdrop {
                    background-color: rgb(18 18 19 / 0.5);
                }
                #didomi-host .didomi-exterior-border {
                    padding: 0;
                    border: none;
                    border-radius: 0px;
                }
                #didomi-host .didomi-popup-container {
                    border: none;
                    border-radius: 0px;
                }
                .didomi-popup-notice-logo-container{
                    display: none!important;
                }
                #didomi-host .didomi-popup-notice.didomi-popup-notice-with-data-processing {
                    padding: 40px!important;
                }
                #didomi-host .didomi-popup-container .didomi-popup-notice-logo-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                #didomi-host .didomi-popup-notice-optin-type .didomi-popup-notice-buttons{
                    align-items: center;
                }
                #didomi-host .didomi-popup-notice-optin-type .didomi-popup-notice-buttons .didomi-components-button {
                    min-height: 40px;
                }
                #didomi-host .didomi-components-radio__option {
                    display: inline-flex;
                    align-items: center;
                }
                #didomi-host .didomi-components-accordion .didomi-content.active > div > span {
                    padding: 0 15px;
                    display: block;
                }
                #didomi-host .didomi-consent-popup-preferences-purposes .didomi-consent-popup-data-processing__description_legal_icon svg {
                    margin: 0!important;
                }
                #didomi-host .didomi-popup-notice-optin-type .didomi-continue-without-agreeing,
                #didomi-host .didomi-mobile .didomi-popup-notice-optin-type .didomi-continue-without-agreeing{
                    display: none;
                }
                #didomi-host .didomi-popup-notice .didomi-popup-notice-subtext{
                    color: #787575;
                }
                #didomi-notice-learn-more-button{
                    padding:0!important;
                }
                .didomi-screen-xsmall .didomi-popup-notice-buttons{
                    flex-direction: row!important;
                    gap: 10px;
                }
                .didomi-screen-xsmall .didomi-popup-notice-buttons .didomi-button{
                    min-width: 160px!important;
                }
            `,
        },
    };

    return (
        <DidomiSDK
            apiKey={publicRuntimeConfig.DIDOMI_PUBLIC_API_KEY}
            config={config}
        />
    );
};

export default DidomiScript;
