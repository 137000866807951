import { useRouter } from 'next/router';
import { SupportedLocale } from '@/core/i18n/locales';
import ROUTES_CONFIG from 'routes.config';

const buildLocalizations = (path: string, locale: SupportedLocale) => {
    for (const routeConfig of Object.values(ROUTES_CONFIG)) {
        const hasMatchingRoute = routeConfig.some((route) => route.locale === locale && route.path === path);

        if (hasMatchingRoute) {
            return routeConfig.map((route) => ({
                locale: route.locale,
                slug: route.path,
            }));
        }
    }

    return [];
};

const useLocalization = () => {
    const { asPath: pagePath, locale } = useRouter();

    return buildLocalizations(pagePath, locale);
};

export default useLocalization;
