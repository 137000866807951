import Head from 'next/head';
import React, { FC } from 'react';
import useLocalization from '@/utils/hooks/useLocalization';

const HrefLang : FC = () => {
    const localizations = useLocalization();

    return (
        <Head>
            {localizations
                .map((locale) => (
                    <link
                        key={locale.locale}
                        data-testid="hreftags"
                        href={locale.slug}
                        hrefLang={locale.locale}
                        rel="alternate"
                    />
                ))}
        </Head>
    );
};

export default HrefLang;
