export const LOCALES = {
    FR: 'fr',
    FR_CH: 'fr-CH',
    ES: 'es',
    CA: 'ca',
    CA_ES: 'ca-ES',
    DE: 'de',
    DE_CH: 'de-CH',
    PT: 'pt',
    EN: 'en',
} as const;

export const DEFAULT_LOCALE = LOCALES.FR;
export const AVAILABLE_LOCALES = Object.values(LOCALES);

export type SupportedLocale = typeof AVAILABLE_LOCALES[number]

export const isSupportedLocale = (
    locale: string,
): locale is SupportedLocale => AVAILABLE_LOCALES.includes(locale as SupportedLocale);

export const getLanguage = (locale: SupportedLocale) => locale.split('-')[0];
