import classNames from 'classnames';
import React, { ChangeEvent, cloneElement, FC, KeyboardEventHandler, ReactElement, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import style from './autocomplete.module.css';
import { InputFieldProps } from '@/ui/components/form/field';
import Icon from '@/ui/components/icon';
import useSearchForm from '@/ui/components/search/form/useSearchForm';
import Typography from '@/ui/components/typography';

type placesPredictions = google.maps.places.AutocompletePrediction;

type AutoCompletePlacesProps = {
    input: ReactElement<InputFieldProps>,
    submitOnPlacePredictionSelect?: boolean,
    onPlacePredictionsSelect?: (placesPredictions: placesPredictions) => void
    className?: string,
}

const AutoCompletePlaces: FC<AutoCompletePlacesProps> = ({
    input: Input,
    submitOnPlacePredictionSelect = true,
    onPlacePredictionsSelect,
    className = '',
}) => {
    const {
        onSubmit,
        placePredictions,
        setSelectedPlacePrediction,
        isPlacePredictionsLoading,
        setCanUpdatePlacePredictions,
    } = useSearchForm();
    const { register, setValue } = useFormContext();
    const { onChange, ...locationField } = register('location');
    const [opened, setOpened] = useState<boolean>(false);
    const [focused, setFocused] = useState<number>(0);
    useEffect(() => {
        setFocused(0);
    }, [placePredictions]);

    const choosePlacePrediction = (index: number) => {
        setSelectedPlacePrediction(placePredictions[index]);
        setValue('location', placePredictions[index].description);
        setOpened(false);
        if (typeof onPlacePredictionsSelect === 'function') {
            onPlacePredictionsSelect(placePredictions[index]);
        }
        if (submitOnPlacePredictionSelect) {
            onSubmit();
        }
    };

    const selectOption = (index: number) => {
        setFocused(index);
        choosePlacePrediction(index);
    };
    const handleUpArrow = () => {
        setCanUpdatePlacePredictions(false);
        if (focused > 0) {
            setFocused(Math.max(0, focused - 1));
        }
    };
    const handleDownArrow = () => {
        setCanUpdatePlacePredictions(false);
        setFocused(Math.min(focused + 1, placePredictions.length - 1));
    };

    const handleEnter = () => {
        if (placePredictions.length > 0) {
            choosePlacePrediction(focused);
        }
    };
    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
        setCanUpdatePlacePredictions(true);
        switch (event.code?.toLowerCase()) {
            case 'arrowup':
                handleUpArrow();
                break;
            case 'arrowdown':
                handleDownArrow();
                break;
            case 'enter':
                if (!submitOnPlacePredictionSelect) {
                    event.preventDefault();
                }
                handleEnter();
                break;
        }
    };

    return (
        <>
            <div className={classNames('relative', className)}>
                {cloneElement(Input, {
                    ...locationField,
                    'aria-expanded': placePredictions.length > 0 ? 'true' : 'false',
                    'aria-owns': 'location__listbox',
                    onBlur: () => setOpened(false),
                    onFocus: () => setOpened(true),
                    onChange: (event: ChangeEvent) => {
                        setOpened(true);
                        onChange(event);
                    },
                    onKeyDown: handleKeyDown,
                    role: 'combobox',
                })}
                {!isPlacePredictionsLoading && opened && placePredictions.length > 0 && (
                    <ul
                        className={
                            classNames(
                                'bg-white border border-grey-stroke',
                                'absolute bottom-[1px] left-0 right-0 z-10 translate-y-full',
                            )
                        }
                        id="location__listbox"
                        role='listbox'
                    >
                        {placePredictions.map((item: { description?: string, place_id?: string }, index) => (
                            <li
                                key={item.description}
                                aria-posinset={index + 1}
                                aria-selected={focused === index ? 'true' : 'false'}
                                aria-setsize={placePredictions.length}
                                className={classNames(
                                    style.autoCompleteListItem,
                                    {
                                        'bg-light': focused === index,
                                    },
                                )}
                                onClick={() => {
                                    selectOption(index);
                                }}
                                onKeyDown={(event) => {
                                    if (event.code.toLowerCase() === 'enter') {
                                        selectOption(index);
                                    }
                                }}
                                onMouseDown={(event) => event.preventDefault()}
                                role='option'
                                tabIndex={-1}
                            >
                                <span
                                    className={style.autoCompleteListItemIconContainer}
                                >
                                    <Icon name="icon-marker" size={14} />
                                </span>
                                <Typography
                                    as="span"
                                    className="!font-bold"
                                    variant="small"
                                >{item.description}
                                </Typography>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            <input {...register('lat')} type="hidden" />
            <input {...register('lng')} type="hidden" />
        </>
    );
};

export default AutoCompletePlaces;
