import { Center, Footer, Header, ParticularAd, Website } from '@homebox/client';
import { createContext, Dispatch, FC, SetStateAction } from 'react';
import { PoppinName } from '@/utils/types/PoppinName';
import { NavigationItem } from '@/utils/types/strapi-plugin-navigation.d.';

interface ContextValue<T> {
    website: Website
    header: Header,
    headerNavigation: Record<'findBox' | 'services' | 'enterprise' | 'volume' | 'magazine', NavigationItem[]>,
    footer: Footer,
    footerNavigations: Record<'more' | 'center' | 'services' | 'others' | 'legal', NavigationItem[]>,
    page: T,
    funnelId?: number,
    setOpenedPoppin: (poppinName: PoppinName | null) => void,
    selectedMarker: Center | ParticularAd | null,
    setSelectedMarker: (center: Center | ParticularAd | null) => void,
    setAuthCallback: Dispatch<SetStateAction<() => void>>,
    connectUser: () => void,
    referer: string,
    countryCenters: Center[],
}

export const AppContext = createContext<ContextValue<any> | null>(null);

export const AppProvider: FC<{ applicationData: ContextValue<any> }> = ({ children, applicationData }) => (
    <AppContext.Provider value={applicationData}>{children}</AppContext.Provider>
);

export type { ContextValue };
