import { useContext } from 'react';
import { AppContext, ContextValue } from '../AppProvider';

const useAppData = <T, >(): ContextValue<T> => {
    const value = useContext(AppContext);

    if (!value) {
        throw new Error('App context is not defined');
    }

    return value;
};

export default useAppData;
