const CURRENCIES = {
    EUR: 'EUR',
    CHF: 'CHF',
} as const;

export const AVAILABLE_CURRENCIES = Object.values(CURRENCIES);

export type SupportedCurrency = typeof AVAILABLE_CURRENCIES[number]

export const isValidCurrency = (
    currency: string,
): currency is SupportedCurrency => AVAILABLE_CURRENCIES.includes(currency as SupportedCurrency);

export default CURRENCIES;
