import { CSSProperties, FC } from 'react';

type IconProps = {
    name: string,
    size?: number,
    className?: string
}

const Icon: FC<IconProps> = ({ name, size, className }) => {
    const style = { '--svg-color': 'currentColor' } as CSSProperties;

    return (
        <svg aria-hidden="true" className={`icon ${className}`} height={size} style={style} width={size}>
            <use href={`/icons.svg#${name}`} />
        </svg>
    );
};

Icon.defaultProps = {
    className: '',
    size: 24,
};

export default Icon;
