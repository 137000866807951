import { useRouter } from 'next/router';
import React, { FC, FormEvent, FormHTMLAttributes, ReactNode, useEffect, useRef, useState } from 'react';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import FindBoxButton, { FinxBoxButtonProps } from '@/ui/components/button/find-a-box';
import { SearchFormProvider } from '@/ui/components/search/form/SearchFormProvider';
import useSearchForm from '@/ui/components/search/form/useSearchForm';
import { searchCity } from '@/utils/gtm';
import useRouteTranslator from '@/utils/hooks/useRouteTranslator';
import useWebsiteConfig from '@/utils/hooks/useWebsiteConfig';

type AutocompletePrediction = google.maps.places.AutocompletePrediction;
type PlaceResult = google.maps.places.PlaceResult;
type PlacesServiceStatus = google.maps.places.PlacesServiceStatus;

export const SearchFormSubmit: FC<FinxBoxButtonProps> = ({ ...props }) => {
    const { isSubmitting } = useSearchForm();

    return <FindBoxButton {...props} displayLoader={isSubmitting} />;
};

type FormValues = {
    location: string,
    lat: string,
    lng: string,
}
const SearchForm: FC<{ children: ReactNode } & FormHTMLAttributes<HTMLFormElement> & { searchCountry?: string }> = ({
    children,
    action,
    method = 'get',
    onSubmit,
    searchCountry,
    ...props
}) => {
    const { searchCountries, locale, isEurope } = useWebsiteConfig();
    const router = useRouter();
    const methods = useForm<FormValues>();
    const {
        watch,
        setValue,
    } = methods;

    const [displayLoader, setDisplayLoader] = useState<boolean>(false);
    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = useGoogle({ debounce: 50 });
    const placePrediction = useRef<AutocompletePrediction | null>(null);
    const setSelectedPlacePrediction = (value: AutocompletePrediction | null) => {
        placePrediction.current = value;
    };
    const canUpdatePlacePredictions = useRef<boolean>(true);
    const setCanUpdatePlacePredictions = (value: boolean) => {
        canUpdatePlacePredictions.current = value;
    };
    const translateRoute = useRouteTranslator();
    const defaultAction = translateRoute('SEARCH');
    const findMyBoxPage = translateRoute('FIND_MY_BOX');
    const locationWatch = watch('location') as string;

    useEffect(() => {
        if (canUpdatePlacePredictions.current) {
            getPlacePredictions({
                input: locationWatch,
                componentRestrictions: { country: isEurope && searchCountry ? [searchCountry] : searchCountries },
                language: locale,
            });
        }
    }, [locationWatch, canUpdatePlacePredictions]);

    const getPlaceDetails = (
        place: AutocompletePrediction,
        callback: (a: PlaceResult | null, b: PlacesServiceStatus) => void,
    ) => {
        if (placesService) {
            placesService.getDetails(
                { placeId: place.place_id },
                callback,
            );
        }
    };

    useEffect(() => {
        if (placePrediction && placePrediction.current) {
            setValue('location', placePrediction.current.description);
        }
    }, [placePrediction]);

    const goToSearchPage = (location: string, lat: string, lng: string) => {
        const destination = !lat
            || !lng
            || Number.isNaN(parseFloat(lat.toString()))
            || Number.isNaN(parseFloat(lng.toString()))
            ? findMyBoxPage
            : (action || defaultAction);

        router.push({
            pathname: destination,
            query: {
                location,
                lat,
                lng,
            },
        });
    };

    const getSelectedPlace = () => {
        if (!placePrediction.current && placePredictions.length > 0) {
            return placePredictions[0];
        }

        return placePrediction.current;
    };
    const onFormSubmit: SubmitHandler<FormValues> = (data, event) => {
        if (displayLoader) {
            return;
        }
        if (data.location === '') {
            event?.preventDefault();

            return;
        }
        setDisplayLoader(true);
        searchCity(data.location);
        if (onSubmit && event) {
            onSubmit(event as FormEvent<HTMLFormElement>);
        }
        const selectedPlace = getSelectedPlace();
        if (selectedPlace) {
            getPlaceDetails(selectedPlace, (result) => {
                goToSearchPage(
                    selectedPlace.description,
                    result?.geometry?.location?.lat().toString() || '',
                    result?.geometry?.location?.lng().toString() || '',
                );
            });

            return;
        }

        goToSearchPage(data.location, '', '');
    };

    const onSubmitHandler = methods.handleSubmit(onFormSubmit, () => setDisplayLoader(false));

    return (
        <FormProvider {...methods}>
            <form
                {...props}
                action={action || defaultAction}
                method={method}
                onSubmit={onSubmitHandler}
            >
                <SearchFormProvider
                    data={{
                        onSubmit: onSubmitHandler,
                        isSubmitting: displayLoader,
                        setIsSubmitting: setDisplayLoader,
                        placePredictions,
                        setSelectedPlacePrediction,
                        isPlacePredictionsLoading,
                        setCanUpdatePlacePredictions,
                    }}
                >
                    {children}
                </SearchFormProvider>
            </form>
        </FormProvider>
    );
};

export default SearchForm;
