/* eslint-disable max-len */
import { LOCALES, SupportedLocale } from '@/core/i18n/locales';

export type RouteConfig = Record<string, { locale: SupportedLocale; path: string; resource: string }[]>

const ROUTES_CONFIG = {
    INDEX: [
        { locale: LOCALES.FR, path: '/', resource: '/__route/homepage' },
        { locale: LOCALES.ES, path: '/', resource: '/__route/homepage' },
        { locale: LOCALES.CA_ES, path: '/', resource: '/__route/homepage' },
        { locale: LOCALES.CA, path: '/', resource: '/__route/homepage-light' },
        { locale: LOCALES.DE, path: '/', resource: '/__route/homepage' },
        { locale: LOCALES.DE_CH, path: '/', resource: '/__route/homepage-light' },
        { locale: LOCALES.FR_CH, path: '/', resource: '/__route/homepage-light' },
        { locale: LOCALES.PT, path: '/', resource: '/__route/homepage-light' },
        { locale: LOCALES.EN, path: '/', resource: '/__route/homepage' },
    ],
    AVIS_VERIFIE: [
        { locale: LOCALES.FR, path: '/avis-verifies', resource: '/__route/avis-verifies' },
        { locale: LOCALES.ES, path: '/opiniones-verificadas', resource: '/__route/avis-verifies' },
        { locale: LOCALES.CA_ES, path: '/opinions-verificades', resource: '/__route/avis-verifies' },
    ],
    BOOKING: [
        { locale: LOCALES.FR, path: '/reservation-en-ligne/choix-du-box', resource: '/__route/reservation-en-ligne/choix-du-box' },
        { locale: LOCALES.ES, path: '/reservar-en-linea/cuadro-de-eleccion', resource: '/__route/reservation-en-ligne/choix-du-box' },
        { locale: LOCALES.CA_ES, path: '/reservar-en-linia/quadre-eleccio', resource: '/__route/reservation-en-ligne/choix-du-box' },
        { locale: LOCALES.CA, path: '/tria-caixa', resource: '/__route/reservation-en-ligne/choix-du-box' },
        { locale: LOCALES.DE, path: '/online-buchung/auswahlbox', resource: '/__route/reservation-en-ligne/choix-du-box' },
        { locale: LOCALES.DE_CH, path: '/online-buchung/auswahlbox', resource: '/__route/reservation-en-ligne/choix-du-box' },
        { locale: LOCALES.FR_CH, path: '/reservation-en-ligne/choix-du-box', resource: '/__route/reservation-en-ligne/choix-du-box' },
        { locale: LOCALES.PT, path: '/escolher-espaco', resource: '/__route/reservation-en-ligne/choix-du-box' },
        { locale: LOCALES.EN, path: '/online-booking/choose-box', resource: '/__route/reservation-en-ligne/choix-du-box' },
    ],
    BOOKING_COMPLETE: [
        { locale: LOCALES.FR, path: '/center-booking/complete', resource: '/__route/center-booking/complete' },
        { locale: LOCALES.ES, path: '/center-booking/complete', resource: '/__route/center-booking/complete' },
        { locale: LOCALES.CA_ES, path: '/center-booking/complete', resource: '/__route/center-booking/complete' },
        { locale: LOCALES.CA, path: '/center-booking/complete', resource: '/__route/center-booking/complete' },
        { locale: LOCALES.DE, path: '/center-booking/complete', resource: '/__route/center-booking/complete' },
        { locale: LOCALES.DE_CH, path: '/center-booking/complete', resource: '/__route/center-booking/complete' },
        { locale: LOCALES.FR_CH, path: '/center-booking/complete', resource: '/__route/center-booking/complete' },
        { locale: LOCALES.PT, path: '/center-booking/complete', resource: '/__route/center-booking/complete' },
        { locale: LOCALES.EN, path: '/center-booking/complete', resource: '/__route/center-booking/complete' },
    ],
    CITY: [
        { locale: LOCALES.FR, path: '/:slug', resource: '/__route/[slug]' },
        { locale: LOCALES.ES, path: '/alquiler-trasteros-self-storage/:slug', resource: '/__route/_variants/search-or-city/[slug]' },
        { locale: LOCALES.CA_ES, path: '/lloguer-trasters/:slug', resource: '/__route/_variants/search-or-city/[slug]' },
        { locale: LOCALES.CA, path: '/lloguer-trasters/:slug', resource: '/__route/_variants/search-or-city/[slug]' },
        { locale: LOCALES.DE, path: '/lagerraum-mieten/:slug', resource: '/__route/_variants/search-or-city/[slug]' },
        { locale: LOCALES.DE_CH, path: '/lagerraum-mieten/:slug', resource: '/__route/_variants/search-or-city/[slug]' },
        { locale: LOCALES.FR_CH, path: '/liste-des-gardes-meubles/:slug', resource: '/__route/_variants/search-or-city/[slug]' },
        { locale: LOCALES.PT, path: '/guarda-de-moveis/:slug', resource: '/__route/_variants/search-or-city/[slug]' },
        { locale: LOCALES.EN, path: '/self-storage-list/:slug', resource: '/__route/_variants/search-or-city/[slug]' },
    ],
    CONTACT: [
        { locale: LOCALES.FR, path: '/contactez-nous', resource: '/__route/contactez-nous' },
        { locale: LOCALES.ES, path: '/contacto', resource: '/__route/contactez-nous' },
        { locale: LOCALES.CA_ES, path: '/contacte', resource: '/__route/contactez-nous' },
        { locale: LOCALES.CA, path: '/contactans', resource: '/__route/contactez-nous' },
        { locale: LOCALES.DE, path: '/kontakt', resource: '/__route/contactez-nous' },
        { locale: LOCALES.DE_CH, path: '/kontakt', resource: '/__route/contactez-nous' },
        { locale: LOCALES.FR_CH, path: '/contactez-nous', resource: '/__route/contactez-nous' },
        { locale: LOCALES.PT, path: '/contacte-nos', resource: '/__route/contactez-nous' },
        { locale: LOCALES.EN, path: '/contact-us', resource: '/__route/contactez-nous' },
    ],
    FIND_MY_BOX: [
        { locale: LOCALES.FR, path: '/trouver-mon-box', resource: '/__route/trouver-mon-box' },
        { locale: LOCALES.ES, path: '/encuentra-mi-trastero', resource: '/__route/trouver-mon-box' },
        { locale: LOCALES.CA_ES, path: '/troba-el-meu-traster', resource: '/__route/trouver-mon-box' },
        { locale: LOCALES.CA, path: '/troba-el-meu-traster', resource: '/__route/trouver-mon-box' },
        { locale: LOCALES.DE, path: '/finde-meinen-lagerraum', resource: '/__route/trouver-mon-box' },
        { locale: LOCALES.DE_CH, path: '/finde-meinen-lagerraum', resource: '/__route/trouver-mon-box' },
        { locale: LOCALES.FR_CH, path: '/trouver-mon-box', resource: '/__route/trouver-mon-box' },
        { locale: LOCALES.EN, path: '/find-my-box', resource: '/__route/trouver-mon-box' },
    ],
    FORGET_PASSWORD: [
        { locale: LOCALES.FR, path: '/mot-de-passe-oublie', resource: '/__route/mot-de-pass-oublie' },
    ],
    LIST_DEPARTMENT: [
        { locale: LOCALES.FR, path: '/liste-des-departements/:slug', resource: '/__route/liste-des-departements/[slug]' },
    ],
    LIST_REGION: [
        { locale: LOCALES.FR, path: '/liste-des-regions/:slug', resource: '/__route/liste-des-regions/[slug]' },
    ],
    LIST_COUNTRY: [
        { locale: LOCALES.EN, path: '/self-storage/:slug', resource: '/__route/_variants/trouver-mon-box/[slug]' },
    ],
    LIST_SELFSTORAGE: [
        { locale: LOCALES.FR, path: '/liste-des-gardes-meubles/:slug', resource: '/__route/liste-des-gardes-meubles/[slug]' },
        { locale: LOCALES.ES, path: '/alquiler-trasteros-self-storage/:slug', resource: '/__route/_variants/search-or-city/[slug]' },
        { locale: LOCALES.CA_ES, path: '/lloguer-trasters/:slug', resource: '/__route/_variants/search-or-city/[slug]' },
        { locale: LOCALES.CA, path: '/lloguer-trasters/:slug', resource: '/__route/_variants/search-or-city/[slug]' },
        { locale: LOCALES.DE, path: '/lagerraum-mieten/:slug', resource: '/__route/_variants/search-or-city/[slug]' },
        { locale: LOCALES.DE_CH, path: '/lagerraum-mieten/:slug', resource: '/__route/_variants/search-or-city/[slug]' },
        { locale: LOCALES.FR_CH, path: '/liste-des-gardes-meubles/:slug', resource: '/__route/_variants/search-or-city/[slug]' },
        { locale: LOCALES.PT, path: '/guarda-de-moveis/:slug', resource: '/__route/_variants/search-or-city/[slug]' },
        { locale: LOCALES.EN, path: '/self-storage-list/:slug', resource: '/__route/_variants/search-or-city/[slug]' },
    ],
    MAGAZINE: [
        { locale: LOCALES.FR, path: '/magazine', resource: '/__route/magazine' },
        { locale: LOCALES.ES, path: '/consejos-utiles', resource: '/__route/magazine' },
        { locale: LOCALES.DE, path: '/unsere-tipps', resource: '/__route/magazine' },
        { locale: LOCALES.EN, path: '/useful-tips', resource: '/__route/magazine' },
    ],
    MAGAZINE_SLUG: [
        { locale: LOCALES.FR, path: '/magazine/:slug*', resource: '/__route/magazine/[...slug]' },
        { locale: LOCALES.ES, path: '/consejos-utiles/:slug*', resource: '/__route/magazine/[...slug]' },
        { locale: LOCALES.DE, path: '/unsere-tipps/:slug*', resource: '/__route/magazine/[...slug]' },
        { locale: LOCALES.EN, path: '/useful-tips/:slug*', resource: '/__route/magazine/[...slug]' },
    ],
    PAGE: [
        { locale: LOCALES.FR, path: '/:slug*', resource: '/__route/[...slug]' },
        { locale: LOCALES.ES, path: '/:slug*', resource: '/__route/_variants/page/[...slug]' },
        { locale: LOCALES.CA_ES, path: '/:slug*', resource: '/__route/_variants/page/[...slug]' },
        { locale: LOCALES.CA, path: '/:slug*', resource: '/__route/_variants/page/[...slug]' },
        { locale: LOCALES.DE, path: '/:slug*', resource: '/__route/_variants/page/[...slug]' },
        { locale: LOCALES.DE_CH, path: '/:slug*', resource: '/__route/_variants/page/[...slug]' },
        { locale: LOCALES.FR_CH, path: '/:slug*', resource: '/__route/_variants/page/[...slug]' },
        { locale: LOCALES.PT, path: '/:slug*', resource: '/__route/_variants/page/[...slug]' },
        { locale: LOCALES.EN, path: '/:slug*', resource: '/__route/_variants/page/[...slug]' },
    ],
    SEARCH: [
        { locale: LOCALES.FR, path: '/recherche', resource: '/__route/recherche' },
        { locale: LOCALES.ES, path: '/buscar', resource: '/__route/recherche' },
        { locale: LOCALES.CA_ES, path: '/buscar', resource: '/__route/recherche' },
        { locale: LOCALES.CA, path: '/buscar', resource: '/__route/recherche' },
        { locale: LOCALES.DE, path: '/suche', resource: '/__route/recherche' },
        { locale: LOCALES.DE_CH, path: '/suche', resource: '/__route/recherche' },
        { locale: LOCALES.FR_CH, path: '/recherche', resource: '/__route/recherche' },
        { locale: LOCALES.EN, path: '/search', resource: '/__route/recherche' },
    ],
    SELFSTORAGE_ADD: [
        { locale: LOCALES.FR, path: '/stockage-particuliers/ajouter-une-offre', resource: '/__route/stockage-particuliers/ajouter-une-offre' },
    ],
    SELFSTORAGE_ADD_THANKS: [
        { locale: LOCALES.FR, path: '/stockage-particuliers/ajouter-une-offre/merci', resource: '/__route/stockage-particuliers/ajouter-une-offre/merci' },
    ],
    SELFSTORAGE_UPDATE: [
        { locale: LOCALES.FR, path: '/stockage-particuliers/modifier-mon-offre', resource: '/__route/stockage-particuliers/modifier-mon-offre' },
    ],
    SELFSTORAGE_UPDATE_THANKS: [
        { locale: LOCALES.FR, path: '/stockage-particuliers/modifier-mon-offre/merci', resource: '/__route/stockage-particuliers/modifier-mon-offre/merci' },
    ],
    SELFSTORAGE_MY_ADS: [
        { locale: LOCALES.FR, path: '/stockage-particuliers/mes-annonces', resource: '/__route/stockage-particuliers/mes-annonces' },
    ],
    SELFSTORAGE_MY_ACCOUNT: [
        { locale: LOCALES.FR, path: '/stockage-particuliers/mon-compte', resource: '/__route/stockage-particuliers/mon-compte' },
    ],
    SELFSTORAGE_ADS: [
        { locale: LOCALES.FR, path: '/stockage-particuliers/:slug', resource: '/__route/stockage-particuliers/[slug]' },
    ],
    SIMULATOR: [
        { locale: LOCALES.FR, path: '/simulateur', resource: '/__route/simulateur' },
        { locale: LOCALES.ES, path: '/simulador', resource: '/__route/simulateur' },
        { locale: LOCALES.CA_ES, path: '/simulador', resource: '/__route/simulateur' },
        { locale: LOCALES.CA, path: '/simulador', resource: '/__route/simulateur' },
        { locale: LOCALES.DE, path: '/simulator', resource: '/__route/simulateur' },
        { locale: LOCALES.DE_CH, path: '/simulator', resource: '/__route/simulateur' },
        { locale: LOCALES.FR_CH, path: '/simulateur', resource: '/__route/simulateur' },
        { locale: LOCALES.PT, path: '/simulador', resource: '/__route/simulateur' },
        { locale: LOCALES.EN, path: '/simulator', resource: '/__route/simulateur' },
    ],
};

export type KnownRoute = keyof typeof ROUTES_CONFIG;

export default ROUTES_CONFIG;
