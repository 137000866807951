import classNames from 'classnames';
import React, { ElementType, FC, HTMLAttributes } from 'react';

export interface TypographyProps extends HTMLAttributes<HTMLElement> {
    as?: ElementType,
    variant?: Variant
}

type Variant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'h7' | 'huge' | 'big' | 'normal' | 'small' | 'tiny';

const TypographyStyles: Record<Variant, string> = {
    h1: classNames(
        'text-[32px] leading-[110%] font-normal font-serif',
        '2xl:text-[58px]',
    ),
    h2: classNames(
        'text-[28px] leading-[110%] font-normal font-serif',
        '2xl:text-[42px]',
    ),
    h3: classNames(
        'text-[24px] leading-[110%] font-bold',
        '2xl:text-[34px]',
    ),
    h4: classNames(
        'text-[20px] leading-[110%] font-bold',
        '2xl:text-[24px]',
    ),
    h5: classNames(
        'text-[16px] leading-[110%] font-bold',
        '2xl:text-[18px]',
    ),
    h6: classNames(
        'text-[14px] leading-[110%] font-bold',
        '2xl:text-[16px]',
    ),
    h7: classNames(
        'text-[12px] leading-[110%] font-bold',
        '2xl:text-[14px]',
    ),
    huge: classNames(
        'text-[20px] leading-[150%] font-normal',
        '2xl:text-[26px]',
    ),
    big: classNames(
        'text-[18px] leading-[150%] font-normal',
        '2xl:text-[20px]',
    ),
    normal: classNames(
        'text-[16px] leading-[150%] font-normal',
    ),
    small: classNames(
        'text-[14px] leading-[150%] font-normal',
    ),
    tiny: classNames(
        'text-[12px] leading-[150%] font-normal',
    ),
};

const Typography: FC<TypographyProps> = ({
    as: Tag = 'p',
    variant = 'normal',
    className = '',
    ...props
}) => {
    const style = TypographyStyles[variant];

    return (<Tag className={`break-words ${style} ${className}`} {...props} />);
};

export default Typography;
