import { Center, ParticularAd } from '@homebox/client';
import getConfig from 'next/config';
import { getActivePromotions, getName } from '@/utils/center';

const { publicRuntimeConfig } = getConfig();
export const GTM_ID = publicRuntimeConfig.GOOGLE_TAG_MANAGER_ID;

interface GAEvent extends Record<any, any> {
    event: string
}

export const sendGAEvent = (event: GAEvent) => {
    window.dataLayer.push(event);
};

export const searchCity = (place: string) => {
    sendGAEvent({
        event: 'trouverMonBox',
        localite: place,
    });
};

export const GTMTelephoneCenter = (clicOrigin: 'carte' | 'liste' | 'header' | 'fiche_centre' | 'bandeau_sticky' | 'funnel', center?: Center) => {
    const now = new Date();

    sendGAEvent({
        event: 'clicTelephoneCentre',
        origineClic: clicOrigin,
        ...(center && {
            nomCentre: getName(center),
            surface: center.surface,
            nombreEtoiles: center.avis_verifies_average,
            promotionsActives: getActivePromotions(now, center.promotion_period).length,
        }),
        ...(!center && {
            nomCentre: 'Global',
        }),
    });
};

export const GTMRecallButton = (clicOrigin: 'sidebar' | 'header' | 'fiche_centre' | 'bandeau_sticky' | 'funnel' | 'advise_lightbox', center?: Center | null) => {
    const now = new Date();

    sendGAEvent({
        event: 'popupRappel',
        origineClic: clicOrigin,
        ...(center && {
            nomCentre: getName(center),
            surface: center.surface,
            nombreEtoiles: center.avis_verifies_average,
            promotionsActives: getActivePromotions(now, center.promotion_period).length,
        }),
        ...(!center && {
            nomCentre: 'Global',
        }),
    });
};

export const contactParticular = (particularAd: ParticularAd) => {
    sendGAEvent({
        event: 'contact_particulier',
        nomCentre: particularAd.name,
        surface: particularAd.surface,
    });
};

export const printViewPageCenter = (center: Center | ParticularAd) => {
    const now = new Date();
    const centerName = center['@type'] === 'Center' ? getName(center) : center.name;

    return {
        event: 'eec.detail',
        ecommerce: {
            detail: {
                products: [{
                    name: centerName,
                    id: center.id,
                }],
            },
        },
        nomCentre: centerName,
        surface: center.surface,
        ...(center['@type'] === 'Center' && {
            nombreEtoiles: center.avis_verifies_average,
            promotionsActives: getActivePromotions(now, center.promotion_period).length,
        }),
        typeBox: center['@type'] === 'Center' ? 'Pro' : 'Particuliers',
    };
};

export const GTMClickPriceButton = (center: Center, uiPlace: 'liste' | 'carte' | 'fiche_centre' | 'bandeau_sticky') => {
    const now = new Date();

    sendGAEvent({
        event: 'eec.add',
        ecommerce: {
            currencyCode: 'EUR',
            add: {
                products: [{
                    name: getName(center),
                    id: center.id,
                }],
            },
        },
        nomCentre: getName(center),
        surface: center.surface,
        nombreEtoiles: center.avis_verifies_average,
        promotionsActives: getActivePromotions(now, center.promotion_period).length,
        origineClic: uiPlace,
    });
};

export const GTMEventLogin = (userID: number) => {
    sendGAEvent({
        event: 'login',
        userID,
    });
};

export const GTMEventSignup = (userID: number) => {
    sendGAEvent({
        event: 'sign_up',
        userID,
    });
};

export const GTMPageViewSignUp = (step: string) => {
    sendGAEvent({
        event: 'page_vue',
        nomFormulaire: 'inscription',
        etape: step,
    });
};

export const GTMParticularAdForm = (step: string) => {
    sendGAEvent({
        event: 'page_vue',
        nomFormulaire: 'depot_annonce',
        etape: step,
    });
};
const GTMFunnelPropertiesByName: Record<string, string> = {
    box_size: 'tailleBox',
    entry_date: 'dateEntree',
    duration: 'dureeLocation',
    values_of_goods: 'valeurBiens',
    coupon_text: 'codePromo',
    postal_code: 'codePostal',
    pro: 'typeProfil',
    email: 'email',
};
const getGTMFunnelPropertiesByName = (stepName: string): string | null => GTMFunnelPropertiesByName[stepName] ?? null;

export const GTMFunnelInputEvent = (stepName: string, value: string) => {
    const propertyName = getGTMFunnelPropertiesByName(stepName);
    if (propertyName) {
        sendGAEvent({
            event: 'interactionChamps',
            [propertyName]: value,
        });
    }
};

const sleep = (ms: number = 1000) => new Promise((resolve) => {
    setTimeout(resolve, ms);
});

export const GTMPageFunnelConfirmation = async (transactionId: string, center: Center, tunnelType: string, coupon: string | null = null) => {
    const now = new Date();

    sendGAEvent({
        event: 'eec.purchase',
        ecommerce: {
            purchase: {
                actionField: {
                    id: transactionId,
                    revenue: 1,
                    ...(coupon && { coupon }),
                },
                products: [{
                    name: getName(center),
                    id: center.id,
                }],
            },
            nomCentre: getName(center),
            surface: center.surface,
            nombreEtoiles: center.avis_verifies_average,
            promotionsActives: getActivePromotions(now, center.promotion_period).length,
            typeTransaction: tunnelType,
        },
    });

    // 💩fix : add delay to let Unnest script time to execute
    await sleep(2000);
};

export const GTMFunnelStepByStep = (step: number, stepName: string, center: Center, formName: string = 'tarifs') => {
    const now = new Date();

    sendGAEvent({
        event: 'eec.checkout',
        ecommerce: {
            checkout: {
                actionField: {
                    step,
                },
                products: [{
                    name: getName(center),
                    id: center.id,
                }],
            },
        },
        nomCentre: getName(center),
        surface: center.surface,
        nombreEtoiles: center.avis_verifies_average,
        promotionsActives: getActivePromotions(now, center.promotion_period).length,
        nomFormulaire: formName,
        etape: stepName,
    });
};

export const GTMSimulateurLightBox = () => {
    sendGAEvent({
        event: 'page_vue',
        nomFormulaire: 'simulateur',
        etape: 'lightbox',
    });
};

export const GTMPageViewRappel = (step: string) => {
    sendGAEvent({
        event: 'page_vue',
        nomFormulaire: 'rappel',
        etape: step,
    });
};

export const GTMAroundMe = () => {
    sendGAEvent({
        event: 'clicAutourMoi',
    });
};
