import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { SupportedLocale } from '@/core/i18n/locales';
import getLocalizedRoute from '@/utils/getLocalizedRoute';
import { KnownRoute } from 'routes.config';

const useRouteTranslator = (forcedLocale?: SupportedLocale) => {
    const { locale, defaultLocale } = useRouter();

    const finalLocale = forcedLocale || locale || defaultLocale;

    const routeTranslator = useCallback(
        (name: KnownRoute, params?: Record<string, string>) => getLocalizedRoute(name, finalLocale, params),
        [finalLocale],
    );

    return routeTranslator;
};

export default useRouteTranslator;
