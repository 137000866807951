import classNames from 'classnames';
import React, { FC, forwardRef, TextareaHTMLAttributes } from 'react';
import style from './style.module.css';

export interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    ref?: any,
}

const TextArea: FC<TextAreaProps> = forwardRef(({
    className = '',
    disabled = false,
    ...props
}: TextAreaProps, ref: React.Ref<HTMLTextAreaElement>) => (
    <textarea
        ref={ref}
        className={classNames(style.textarea, className)}
        disabled={disabled}
        {...props}
    />
));

export default TextArea;
