import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { FC } from 'react';
import Icon from '@/ui/components/icon';
import Typography from '@/ui/components/typography';
import { GTMAroundMe } from '@/utils/gtm';
import useAppData from '@/utils/hooks/useAppData';
import useRouteTranslator from '@/utils/hooks/useRouteTranslator';
import useTranslation from '@/utils/hooks/useTranslation';

const AroundMe: FC<{ className?: string }> = ({ className = '' }) => {
    const t = useTranslation();
    const router = useRouter();
    const translatedRoute = useRouteTranslator();
    const searchUrl = translatedRoute('SEARCH');
    const { setOpenedPoppin } = useAppData();

    const getCurrentPosition = () => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                const lat = position.coords.latitude.toString(10);
                const lng = position.coords.longitude.toString(10);

                router.push({
                    pathname: searchUrl,
                    query: { lat, lng },
                });
                setOpenedPoppin(null);
            }, (error) => {
                console.error(error);
            });
        }
    };

    return (
        <Typography
            as="button"
            className={classNames('text-red inline-flex items-center gap-2', className)}
            onClick={() => {
                getCurrentPosition();
                GTMAroundMe();
            }}
            variant="small"
        >
            {t('around_me')}
            <Icon name="icon-marker-area" size={18} />
        </Typography>
    );
};

export default AroundMe;
