import React, { forwardRef, InputHTMLAttributes } from 'react';
import { InputField, InputFieldProps } from '@/ui/components/form/field';
import InputVariant from '@/ui/components/form/inputVariant';
import useTranslation from '@/utils/hooks/useTranslation';

const LocationField = forwardRef(({
    disabled,
    variant = InputVariant.BIG,
    hideLabel = true,
    name = 'location',
    ...props
}: Pick<InputFieldProps, 'variant' | 'hideLabel'> & InputHTMLAttributes<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => {
    const t = useTranslation();

    return (
        <InputField
            ref={ref}
            autoComplete="off"
            disabled={disabled}
            hideLabel={hideLabel}
            label={t('find_storage')}
            name={name}
            placeholder={t('center_search_input_placeholder')}
            variant={variant}
            {...props}
        />
    );
});

export default LocationField;
