import { Seo } from '@homebox/client';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { FC } from 'react';
import { jsonLdScriptProps } from 'react-schemaorg';
import { Organization, PropertyValueSpecification, SearchAction, WebSite } from 'schema-dts';
import { buildFullBaseUrl } from '@/core/i18n';
import useAppData from '@/utils/hooks/useAppData';
import useRouteTranslator from '@/utils/hooks/useRouteTranslator';
import useWebsiteConfig from '@/utils/hooks/useWebsiteConfig';
import { getTitle } from '@/utils/seo';

type SearchActionWithQueryInput = SearchAction & {
    'query-input': PropertyValueSpecification | String;
};

const SEO: FC = () => {
    const { url } = useWebsiteConfig();
    const { page } = useAppData<{ SEO: Seo }>();
    const router = useRouter();

    const routeTranslator = useRouteTranslator();
    const searchRoute = routeTranslator('SEARCH');

    const baseUrl = buildFullBaseUrl(url, router.locale);
    const metaCanonicalUrl = page?.SEO?.canonicalURL || `${baseUrl}${router.asPath}`;

    const searchAction: SearchActionWithQueryInput = {
        '@type': 'SearchAction',
        target: `${baseUrl}/${searchRoute}?location={search_term_string}`,
        'query-input': 'required name=search_term_string',
    };

    return (
        <Head>
            <title key="title">{getTitle(page?.SEO?.metaTitle || '')}</title>
            <meta key="description" content={page?.SEO?.metaDescription} name="description" />
            <meta key="meta-title" content={page?.SEO?.metaTitle} itemProp="name" />
            <meta key="meta-description" content={page?.SEO?.metaDescription} itemProp="description" />
            {page?.SEO?.metaImage
                && <meta content={page.SEO.metaImage.url} itemProp="image" />}
            {metaCanonicalUrl && (<link href={metaCanonicalUrl} rel="canonical" />)}
            <meta key="meta-robots" content={page?.SEO?.metaRobots || 'index,follow'} name="robots" />
            {page?.SEO?.metaSocial?.map((social: any) => {
                if (social.socialNetwork.toLowerCase() === 'facebook') {
                    return (
                        <>
                            <meta content={getTitle(social.title)} name='og:title' />
                            <meta content={social.description} name='og:description' />
                            <meta content={metaCanonicalUrl} name='og:url' />
                            <meta content="HOMEBOX" name='og:site_name' />
                            {social.image.data
                                && <meta content={social.image.url} name='og:image' />}
                        </>
                    );
                }
                if (social.socialNetwork.toLowerCase() === 'twitter') {
                    return (
                        <>
                            <meta content='summary_large_image' name='twitter:card' />
                            <meta content={getTitle(social.title)} name='twitter:title' />
                            <meta content={social.description} name='twitter:description' />
                            {social.image.data
                                && (
                                    <meta
                                        content={social.image.url}
                                        name='twitter:image:src'
                                    />
                                )}
                        </>
                    );
                }

                return null;
            })}
            <script {...jsonLdScriptProps<Organization>({
                '@context': 'https://schema.org',
                '@type': 'Organization',
                name: 'HOMEBOX',
                url: baseUrl,
                logo: `${baseUrl}logo.svg`,
                foundingDate: '1996',
                foundingLocation: 'Paris, France',
                founder: [{ '@type': 'Person', name: 'Nicolas Rousselet' }],
                address: {
                    '@type': 'PostalAddress',
                    streetAddress: '22-28 rue Henri Barbusse',
                    addressLocality: 'Clichy',
                    postalCode: '92110',
                    addressCountry: 'France',
                },
                sameAs: [
                    'https://www.facebook.com/HOMEBOX-194802647229562/',
                    'https://www.linkedin.com/company/homebox',
                ],
            })}
            />
            <script {...jsonLdScriptProps<WebSite>({
                '@context': 'https://schema.org',
                '@type': 'WebSite',
                name: 'HOMEBOX',
                url: baseUrl,
                potentialAction: searchAction,
            })}
            />
        </Head>
    );
};
export default SEO;
