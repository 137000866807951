import React, { ElementType, FC } from 'react';
import Button, { ButtonProps } from '@/ui/components/button/index';
import Icon from '@/ui/components/icon';
import SpinnerLoader from '@/ui/components/loader/spinner';
import useTranslation from '@/utils/hooks/useTranslation';

export type FinxBoxButtonProps = { textClassName?: string, displayLoader?: boolean } & ButtonProps<ElementType>
const FindBoxButton: FC<FinxBoxButtonProps> = ({
    textClassName = '',
    displayLoader = false,
    ...props
}) => {
    const t = useTranslation();

    return (
        <Button {...props}>
            {displayLoader ? (
                <SpinnerLoader />
            ) : (
                <Icon
                    className="w-[18px] h-[18px] md:w-6 md:h-6"
                    name="icon-search"
                    size={24}
                />
            )}
            <span className={textClassName}>{t('find_storage')}</span>
        </Button>
    );
};

export default FindBoxButton;
