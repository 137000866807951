import { Center, ParticularAd } from '@homebox/client';
import { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import { appWithTranslation, SSRConfig } from 'next-i18next';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import '../styles/global.css';
import { ButtonSizes } from '@/ui/components/button';
import DidomiScript from '@/ui/components/didomi/index';
import AutoCompletePlaces from '@/ui/components/form/field/location/AutoCompletePlaces';
import LocationField from '@/ui/components/form/field/location/locationField';
import ScriptGTMInitDataLayer from '@/ui/components/googleTagManager/initDataLayer';
import HrefLang from '@/ui/components/hrefLang';
import AroundMe from '@/ui/components/search/aroundMe/aroundMe';
import SearchForm, { SearchFormSubmit } from '@/ui/components/search/form/search';
import SEO from '@/ui/components/seo';
import Typography from '@/ui/components/typography';
import { AppProvider } from '@/utils/AppProvider';
import { AppDefaultProps } from '@/utils/createServerPropsWithAppData';
import useTranslation from '@/utils/hooks/useTranslation';
import setScrollbarWidth from '@/utils/scrollBarWidth';
import { PoppinName } from '@/utils/types/PoppinName';
import { WebsiteProvider } from '@/utils/website/WebsiteProvider';

const Poppin = dynamic(() => import('@/ui/components/modal/poppin'));
const Lightbox = dynamic(() => import('@/ui/components/modal/lightbox'));
const I18nPoppin = dynamic(() => import('@/ui/components/modal/poppin/i18n'));
const AuthForm = dynamic(() => import('@/ui/components/auth/form'));
const RecallForm = dynamic(() => import('@/ui/components/recall/form'));

type CustomAppProps = AppDefaultProps & {
    session: Session,
    ressource?: string,
} & SSRConfig

type AppPropsWithLayout = AppProps<CustomAppProps>

const MyApp = ({ Component, pageProps }: AppPropsWithLayout) => {
    const router = useRouter();
    const t = useTranslation();

    const [openedPoppin, setOpenedPoppin] = useState<PoppinName | null>(null);
    const [selectedMarker, setSelectedMarker] = useState<Center | ParticularAd | null>(null);
    const [authCallback, setAuthCallback] = useState(() => () => {
        if (pageProps.website && pageProps.website.name !== 'HOMEBOX France') {
            router.push({
                pathname: '/stockage-particuliers/mes-annonces',
            });
        }
    });

    const connectUser = () => {
        authCallback();
    };
    const appData = {
        ...pageProps,
        setOpenedPoppin,
        selectedMarker,
        setSelectedMarker,
        setAuthCallback,
        connectUser,
    };

    useEffect(() => {
        setScrollbarWidth();
    }, []);

    return (
        <AppProvider applicationData={appData}>
            <WebsiteProvider website={pageProps.website}>
                <SessionProvider session={pageProps.session}>
                    <>
                        <Head>
                            <meta key="meta-charset" charSet="utf-8" />
                            <meta key="meta-viewport" content="width=device-width" name="viewport" />
                            <script
                                src="//cdn-4.convertexperiments.com/v1/js/10046135-10046523.js"
                                type="text/javascript"
                            />
                        </Head>
                        <DidomiScript />
                        <SEO />
                        <HrefLang />
                        <ScriptGTMInitDataLayer session={pageProps.session} />
                        <Component {...pageProps} />
                        {openedPoppin === 'search-box' && (
                            <Poppin
                                isOpen={openedPoppin === 'search-box'}
                                onRequestClose={() => setOpenedPoppin(null)}
                            >
                                <header className="text-center mb-3 max-w-[360px] w-full mx-auto">
                                    <Typography
                                        as="p"
                                        className="mb-1"
                                        variant="h3"
                                    >{t('find_storage')}
                                    </Typography>
                                    <Typography
                                        as="p"
                                        className="text-dark-grey"
                                        variant="normal"
                                    >{t('form_box_location_search_text')}
                                    </Typography>
                                </header>
                                <SearchForm className="flex flex-col space-y-2 max-w-[512px] w-full mx-auto">
                                    <div>
                                        <AutoCompletePlaces
                                            input={<LocationField id="location-poppin" autoFocus />}
                                        />
                                        <AroundMe className="mt-2 mb-2 pl-4" />
                                    </div>
                                    <SearchFormSubmit sizeVariant={ButtonSizes.BIG} type="submit" />
                                </SearchForm>
                            </Poppin>
                        )}
                        {openedPoppin === 'auth-form'
                                    && (
                                        <Lightbox
                                            isOpen={openedPoppin === 'auth-form'}
                                            onRequestClose={() => setOpenedPoppin(null)}
                                        >
                                            <AuthForm className="overflow-auto h-full" />
                                        </Lightbox>
                                    )}
                        {openedPoppin === 'recall-form'
                                    && (
                                        <Lightbox
                                            isOpen={openedPoppin === 'recall-form'}
                                            onRequestClose={() => setOpenedPoppin(null)}
                                        >
                                            <RecallForm
                                                centers={pageProps.recallCenters}
                                                className="overflow-auto h-full"
                                            />
                                        </Lightbox>
                                    )}
                        {openedPoppin === 'i18n-poppin'
                                    && (
                                        <I18nPoppin
                                            isOpen={openedPoppin === 'i18n-poppin'}
                                            onRequestClose={() => setOpenedPoppin(null)}
                                        />
                                    )}
                    </>
                </SessionProvider>
            </WebsiteProvider>
        </AppProvider>
    );
};

export default appWithTranslation(MyApp);
