import { Fragment, ReactElement } from 'react';

const nl2br = (str: string): ReactElement[] => str.split('\n').map((item, index) => (
    <Fragment
        key={item.toString()}
    >{index > 0 && <br />}{item}
    </Fragment>
));

const capitalize = (str: string) => str[0].toUpperCase() + str.slice(1);

export { nl2br, capitalize };
