import { compile } from 'path-to-regexp';
import ROUTES_CONFIG, { KnownRoute } from '../routes.config';
import { SupportedLocale } from '@/core/i18n/locales';

/**
 *
 * @param path Route path containing dynamic params | exemple: '/blog/article/:slug'
 * @param params Object containing value for params | exemple: { slug: 'top-10-restaurants' }
 * @returns Interpolated path                       | exemple: '/blog/article/top-10-restaurants'
 */
const buildDynamicPath = (path: string, params: Record<string, string>) => {
    const toPath = compile(path);

    try {
        const formattedParams = Object.fromEntries(
            Object.entries(params).map(([key, parameter]) => {
                if (!parameter.includes('/')) {
                    return [key, parameter];
                }

                const formattedParam = parameter
                    .split('/')
                    .filter((param) => param !== '');

                return [key, formattedParam];
            }),
        );

        return toPath(formattedParams);
    } catch (error) {
        console.error(error);

        return toPath(params);
    }
};

const getLocalizedRoute = (
    name: KnownRoute,
    locale: SupportedLocale,
    params?: Record<string, string>,
) => {
    const routesConfig = ROUTES_CONFIG[name];

    const routeConfig = routesConfig.find((route) => route.locale === locale);

    if (!routeConfig?.path) {
        return '/';
    }

    const { path } = routeConfig;

    if (params) {
        return buildDynamicPath(path, params);
    }

    return path;
};

export default getLocalizedRoute;
