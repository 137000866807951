import { Center, PromotionPeriod, TunnelType, TunnelTypeByDate, TunnelTypeByDay } from '@homebox/client';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import dateTimeZoned from '@/utils/dateTimezone';

dayjs.extend(isBetween);

const getTunnelTypeByDate = (entries: TunnelTypeByDate[]): TunnelType['type'] | null => {
    const [matchingDate] = entries.filter((tunnelByDate) => {
        const today = dateTimeZoned().tz();
        const tunnelDate = dayjs(tunnelByDate.date);
        if (tunnelDate.format('YYYY-MM-DD') !== today.format('YYYY-MM-DD')) {
            return false;
        }
        if (tunnelByDate.open_time !== null) {
            if (tunnelByDate.open_time > today.format('HH:mm:ss')) {
                return false;
            }
        }
        if (tunnelByDate.close_time !== null) {
            if (tunnelByDate.close_time < today.format('HH:mm:ss')) {
                return false;
            }
        }

        return true;
    });
    if (matchingDate !== undefined && matchingDate.tunnel_type) {
        return matchingDate.tunnel_type.type;
    }

    return null;
};

const getTunnelTypeByDay = (entries: TunnelTypeByDay[]): TunnelType['type'] | null => {
    const [matchingDate] = entries.filter((tunnelByDay) => {
        const todayDate = dateTimeZoned().tz();
        const day = new Intl.DateTimeFormat('en-US', { weekday: 'long' })
            .format(new Date(todayDate.toString())).toLowerCase();
        if (day !== tunnelByDay.day_label) {
            return false;
        }
        const currentTime = todayDate.format('HH:mm:ss');
        if (tunnelByDay.open_time > currentTime) {
            return false;
        }

        return tunnelByDay.close_time > currentTime;
    });
    if (matchingDate !== undefined && matchingDate.tunnel_type) {
        return matchingDate.tunnel_type.type;
    }

    return null;
};

export const getCurrentTunnelType = (center: Center): NonNullable<TunnelType['type']> => {
    const typeByDate = getTunnelTypeByDate(center.tunnel_type_by_date);
    const typeByDay = getTunnelTypeByDay(center.tunnel_type_by_day);

    return typeByDate || typeByDay || center.tunnel_type?.type || 'quotation';
};

export const getName = (center: Center): string => `${center.type_center} ${center.name}`;

const isBetweenDays = (date: Date, minDate: Date, maxDate: Date) => dayjs(date).isBetween(minDate, maxDate, 'day', '[]');

export const getActivePromotions = (now: Date, promotionPeriod: PromotionPeriod[]) => {
    if (!promotionPeriod) {
        return [];
    }

    return promotionPeriod.filter((promotion) => {
        const minDate = new Date(promotion.start_date || 'now');
        const maxDate = new Date(promotion.end_date || 'now');
        const isInDateRange = isBetweenDays(now, minDate, maxDate);

        return isInDateRange && promotion.publish && promotion.promotion !== null;
    });
};

export const getAllCenterPromotions = (center: Center) => {
    const nationalPromotions = (center?.promotion_nationals || []).map((promo) => promo.promotion);
    const centerPromotion = center?.promotion_period || [];

    return [...nationalPromotions, ...centerPromotion];
};
