import classNames from 'classnames';
import React, { FC, LabelHTMLAttributes } from 'react';

const FormLabel: FC<LabelHTMLAttributes<HTMLLabelElement>> = ({ className, ...props }) => (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label
        className={classNames(
            'text-black font-bold text-[12px] leading-[16px]',
            className,
        )}
        {...props}
    />
);

export default FormLabel;
