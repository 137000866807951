import classNames from 'classnames';
import React, { FC, forwardRef, LabelHTMLAttributes } from 'react';
import Input, { InputProps } from '@/ui/components/form/input';
import InputVariant from '@/ui/components/form/inputVariant';
import FormLabel from '@/ui/components/form/label';
import TextArea, { TextAreaProps } from '@/ui/components/form/textarea';
import TooltipHelp from '@/ui/components/form/tooltipHelp';

interface FieldProps {
    label: string,
    hideLabel?: boolean,
    helpText?: string | null,
}

export interface InputFieldProps extends FieldProps, InputProps {
}

export interface TextareaFieldProps extends FieldProps, TextAreaProps {
}

const FieldLabelWithHelp: FC<LabelHTMLAttributes<HTMLLabelElement> & { helpText?: string | null }> = ({
    helpText,
    ...props
}) => {
    if (helpText) {
        return (
            <div className="flex gap-1 items-baseline">
                <FormLabel {...props} />
                <TooltipHelp helpText={helpText} />
            </div>
        );
    }

    return <FormLabel {...props} />;
};

const InputField: FC<InputFieldProps> = forwardRef(({
    label,
    name,
    disabled,
    variant,
    hideLabel = false,
    helpText = null,
    ...rest
}: InputFieldProps, ref: React.Ref<HTMLInputElement>) => (
    <div className={classNames(
        'w-full leading-[0px]',
        { 'flex flex-col items-start': variant === InputVariant.BIG },
    )}
    >
        <FieldLabelWithHelp
            className={classNames(
                { 'text-grey': disabled },
                { 'sr-only': variant === InputVariant.BIG || hideLabel },
            )}
            helpText={helpText}
            htmlFor={name}
        >
            {label}
        </FieldLabelWithHelp>
        <Input ref={ref} id={name} name={name} {...rest} disabled={disabled} variant={variant} />
    </div>
));

const TextareaField: FC<TextareaFieldProps> = forwardRef(({
    label,
    name,
    disabled,
    helpText = null,
    ...rest
}: TextareaFieldProps, ref: React.Ref<HTMLInputElement>) => (
    <div className={classNames(
        'w-full',
    )}
    >
        <FieldLabelWithHelp
            className={classNames(
                { 'text-grey': disabled },
            )}
            helpText={helpText}
            htmlFor={name}
        >
            {label}
        </FieldLabelWithHelp>
        <TextArea ref={ref} id={name} name={name} {...rest} disabled={disabled} />
    </div>
));

export { InputField, TextareaField, FieldLabelWithHelp };
