import classNames from 'classnames';
import React, { ComponentProps, ElementType, HTMLAttributes } from 'react';
import styles from './button.module.css';
import SpinnerLoader from '@/ui/components/loader/spinner';

export enum ButtonSizes {
    DEFAULT = 'default',
    MEDIUM = 'medium',
    BIG = 'big',
}

export enum ButtonColors {
    DEFAULT = 'default',
    GRAY = 'gray',
    BLACK = 'black',
    BLUE = 'blue',
    GREEN = 'green',
    APRICOT = 'apricot-peach',
    PROVINCIAL = 'provincial-pink',
    GHOST = 'ghost',
}

type ButtonOwnProps<E extends ElementType> = {
    sizeVariant?: ButtonSizes,
    colorVariant?: ButtonColors,
    className?: string,
    asElement?: E,
    isLoading?: boolean
} & HTMLAttributes<ElementType>

export type ButtonProps<E extends ElementType> = ButtonOwnProps<E> &
    Omit<ComponentProps<E>, keyof ButtonOwnProps<E>>

export function getColor(color: string = 'default') {
    if (color === ButtonColors.GRAY) {
        return styles.buttonGray;
    }

    if (color === ButtonColors.BLACK) {
        return styles.buttonBlack;
    }

    if (color === ButtonColors.BLUE) {
        return styles.buttonBlue;
    }

    if (color === ButtonColors.GREEN) {
        return styles.buttonGreen;
    }

    if (color === ButtonColors.APRICOT) {
        return styles.buttonApricotPeach;
    }

    if (color === ButtonColors.PROVINCIAL) {
        return styles.buttonProvincialPink;
    }

    if (color === ButtonColors.GHOST) {
        return styles.buttonGhost;
    }

    return styles.buttonRed;
}

export function getSize(size: string = 'default') {
    if (size === ButtonSizes.MEDIUM) {
        return styles.buttonMediumSize;
    }
    if (size === ButtonSizes.BIG) {
        return styles.buttonBigSize;
    }

    return styles.buttonDefaultSize;
}

const Button = React.forwardRef(({
    asElement: Tag = 'button',
    children,
    sizeVariant = ButtonSizes.DEFAULT,
    colorVariant = ButtonColors.DEFAULT,
    className = '',
    isLoading = false,
    ...props
}: ButtonProps<ElementType>, ref: React.Ref<ElementType>) => {
    const buttonProps = {
        className: classNames(
            styles.button,
            { [styles.buttonLoading]: !isLoading },
            getColor(colorVariant),
            getSize(sizeVariant),
            className,
        ),
    };

    return (
        <Tag ref={ref} {...buttonProps} {...(Tag === 'button' && { type: 'button' })} {...props}>
            <div className={styles.buttonInner}>
                {isLoading ? (
                    <SpinnerLoader />
                ) : children}
            </div>
        </Tag>
    );
});

export default Button;
