import { useContext } from 'react';
import { SearchFormContext, SearchFromContextProps } from '@/ui/components/search/form/SearchFormProvider';

const useSearchForm = (): SearchFromContextProps => {
    const value = useContext(SearchFormContext);

    if (!value) {
        throw new Error('Search Form context is not defined');
    }

    return value;
};

export default useSearchForm;
