import classNames from 'classnames';
import React, { forwardRef, InputHTMLAttributes } from 'react';
import style from './style.module.css';
import InputVariant from '@/ui/components/form/inputVariant';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string,
    variant?: string,
    ref?: any,
}

const Input = forwardRef(({
    className = '',
    type = 'text',
    variant = InputVariant.MEDIUM,
    placeholder = '',
    disabled = false,
    name,
    ...props
}: InputProps, ref: React.Ref<HTMLInputElement>) => {
    const classesSizes = variant === InputVariant.BIG
        ? 'text-[16px] px-4 h-[50px] 2xl:h-[60px]'
        : 'text-[16px] px-3 h-[40px]';

    return (
        <input
            ref={ref}
            className={classNames(style.input, style['hide-arrows'], classesSizes, className)}
            disabled={disabled}
            name={name}
            placeholder={placeholder}
            type={type}
            {...props}
        />
    );
});

export default Input;
