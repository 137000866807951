import { createContext, Dispatch, FC, SetStateAction } from 'react';
import { usePlacesAutocompleteServiceResponse } from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

type SearchFromContextProps = {
    onSubmit: () => void
    isSubmitting: boolean,
    setIsSubmitting: Dispatch<SetStateAction<boolean>>,
    placePredictions: usePlacesAutocompleteServiceResponse['placePredictions'],
    setSelectedPlacePrediction: (value: google.maps.places.AutocompletePrediction | null) => void,
    isPlacePredictionsLoading: boolean,
    setCanUpdatePlacePredictions: (value: boolean) => void,
}

export const SearchFormContext = createContext<SearchFromContextProps | null>(null);

export const SearchFormProvider: FC<{ data: SearchFromContextProps }> = ({ children, data }) => (
    <SearchFormContext.Provider value={data}>{children}</SearchFormContext.Provider>
);

export type { SearchFromContextProps };
