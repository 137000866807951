import { Session } from 'next-auth';
import { useRouter } from 'next/router';
import Script from 'next/script';
import React, { FC } from 'react';

const ScriptGTMInitDataLayer: FC<{ session: Session }> = ({ session }) => {
    const router = useRouter();
    const country = 'HOMEBOX France';
    const user = session?.user;
    const visitorState = user !== undefined ? 'Connecté' : 'Visiteur';

    const initDataLayer = {
        sitePays: country,
        siteLangue: router.locale,
        etatVisiteur: visitorState,
        event: 'dlLoaded',
        ...(session && user && { userId: session.id }),
    };

    return (
        <Script id="gtm-initDataLayer" strategy="afterInteractive" async>
            window.dataLayer.push({JSON.stringify(initDataLayer)})
        </Script>
    );
};

export default ScriptGTMInitDataLayer;
